<template>
  <admin>
    <metatag title="Inquiries"></metatag>

    <!-- redirect to upload form -->
    <page-header>
      <template v-slot:action>
      </template>
    </page-header>
    <v-card>
      <toolbar-menu ref="toolbar" :items.sync="tabletoolbar" :filter-status="true" @update:search="setSearch"
        @update:status="setStatus" @update:model="handleFilterModel"></toolbar-menu>
      <v-card-text class="pa-0">
        <v-data-table :headers="resources.headers" :items="resources.data" :loading="resources.loading"
          :items-per-page="5" :server-items-length="resources.total" :options.sync="resources.options" color="primary"
          item-key="id" class="text-no-wrap">
          <template v-slot:item.id="{ item }">
            <div class="text-no-wrap">
              <!-- Preview -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn text color="primary" icon v-on="on" @click="previewInquiry(item)">
                    {{ item.id }}
                  </v-btn>
                </template>
                <span v-text="'Preview'"></span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.name="{ item }">
            <span>{{ item.first_name }} {{ item.last_name }}</span> <br />
            <span>{{ item.email }}</span> <br />
            <span>{{ item.mobile_number }} </span>
          </template>

          <template v-slot:item.status="{ item }">
            <v-chip label>
              {{ item.status ? item.status.label : "" }}
            </v-chip>
          </template>


          <template v-slot:item.editAction="{ item }">
            <can :code="code">
              <!-- Edit -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="editInquiry(item)" text icon v-on="on">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-text="'Edit'"></span>
              </v-tooltip>
              <!-- Edit -->
            </can>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- edit dialog -->
    <can :code="code">
      <v-dialog v-model="inquiryDialog" max-width="600px" class="overflow-hidden">
        <v-card class="pa-4">
          <v-card-title>
            <h2 title="Edit" class="mb-1">Edit Inquiry # {{ editedItem.id }}</h2>
          </v-card-title>
          <v-card-text class="overflow-y-auto">

            <!-- status -->
            <v-row>
              <v-col cols="12">

                <v-select v-model="editedItem.status" append-icon="mdi-chevron-down" :items="resources.statusOptions"
                  item-text="title" item-value="slug" label="Status" outlined clearable hide-details
                  clear-icon="mdi-close-circle-outline" background-color="selects"></v-select>

              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large color="grey" exact class="ma-1 white--text px-5" @click="inquiryDialog = false">
              Cancel
            </v-btn>

            <v-btn large exact color="green darken-1" class="ma-1 white--text px-5" @click="update">
              <v-icon left>mdi-content-save</v-icon>
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </can>

    <!-- preview dialog -->
    <v-dialog v-model="previewDialog" max-width="900px" class="overflow-hidden">
      <v-card class="pa-4">
        <v-card-title>
          <h2 title="Preview" class="mb-1">#{{ previewInquiryItem.id }}</h2>
        </v-card-title>
        <v-card-text class="overflow-y-auto">
          <!-- Background Details -->
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-bold">{{ "Customer" }}</td>
                  <td>

                    {{ previewInquiryItem.first_name }} {{ previewInquiryItem.last_name }}
                  </td>
                  <td class="font-weight-bold">{{ "Status" }}</td>
                  <td><v-chip label>{{ previewInquiryItem.status.label }}</v-chip></td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Email" }}</td>
                  <td>{{ previewInquiryItem.email }}</td>
                  <td class="font-weight-bold">{{ "Mobile Number" }}</td>
                  <td>{{ previewInquiryItem.mobile_number }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">{{ "Message:" }}</td>
                  <td colspan="3" style="max-width: 150px; font-size: 14px !important;">{{ previewInquiryItem.message ||
                    "No message"}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- Background Details -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="grey" exact class="ma-1 white--text px-5" @click="previewDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as helpers from "@/core/helpers";
import { debounce } from "lodash";
import apiModuleType from "@/services/api/modules/moduleTypeService";
export default {
  data() {
    return {
      filters: [
        { text: "Model", value: "model" },
        { text: "Variant", value: "name" },
        { text: "Year", value: "year" },
        { text: "Price", value: "price" },
        { text: "Status", value: "status" },
      ],
      tabletoolbar: {
        isSearching: false,
        search: null,
        type: "parts-inquiry",
      },
      resources: {
        loading: true,
        headers: [
          {
            text: "ID",
            value: "id",
            align: "left"
          },
          {
            text: "Name",
            value: "name",
            align: "left"
          },
          {
            text: "Status",
            value: "status",
            align: "left"
          },
          {
            text: "Updated At",
            value: "updated_at",
            align: "left"
          },
          {
            text: "Created At",
            value: "created_at",
            align: "left"
          }
        ],
        options: {},
        data: [],
        previewData: [],
        statusOptions: [],
        actionOptions: [],
      },
      code: ['admin_parts', 'dealer_parts'],
      inquiryDialog: false,
      previewDialog: false,
      editedItem: {
        "status": null,
        "id": null,
      },
      previewInquiryItem: {},
      previewInquiryItemDetails: [
        {
          id: 1,
          part_name: "NGK Spartk Plug"
        },
        {
          id: 2,
          part_name: "Honda Genuine Air Filter"
        },
        {
          id: 3,
          part_name: "Motolite Battery"
        },
        {
          id: 4,
          part_name: "Break Pads"
        },
        {
          id: 5,
          part_name: "Muffler"
        },
      ],
      filterData: {
        channel: "form",
        status: "pending",
        q: "",
      },

    };
  },
  watch: {
    "resources.options": {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
        this.getItems(page, itemsPerPage, this.filterData);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      data: "dealerPartInquiries/GET_INQUIRIES",
      userRoles: "auth/GET_ROLES",
      inquiryItems: "dealerPartInquiries/GET_INQUIRY_ITEMS",
    }),
  },
  methods: {
    ...mapActions({
      getInquiries: "dealerPartInquiries/getInquiries",
      getInquiryItems: "dealerPartInquiries/getInquiryItems",
      getUserType: "auth/getUserType",
      updatePartsInquiry: "dealerPartInquiries/updateInquiry",
    }),

    setSearch: debounce(async function (e) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      this.filterData.q = e.target.value;
      this.getItems(page, itemsPerPage, this.filterData);
    }, 300),

    setStatus: debounce(async function (value) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
      // set data prop to check if the value is not null when getting all records by status
      this.filterData.status = value;
      this.getItems(1, itemsPerPage, this.filterData);
    }, 300),

    getItems(page, itemsPerPage, filterData = {}) {
      let data = {
        page,
        per_page: itemsPerPage,
        filters: filterData,
      };

      this.getInquiries(data).then(() => {
        this.resources.data = this.data.data;
        this.resources.total = this.data.meta.total;
        this.resources.loading = false;
        this.$refs.toolbar.items.isSearching = false;
      });
    },

    async handleFilterModel(val) {
      await this.getItems(1, 5, "", val);
    },

    async getStatusList() {
      const { status, data } = await apiModuleType.getStatusList("parts-inquiry");
      if (status === 200) {
        this.resources.statusOptions = data.data;
      }
    },

    editInquiry(item) {
      this.editedItem.id = item.id;
      this.editedItem.status = item.status ? item.status.code : "";
      this.inquiryDialog = true
    },

    update() {
      this.updatePartsInquiry(this.editedItem).then(() => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.resources.options;
        this.getItems(page, itemsPerPage, this.filterData);
        this.inquiryDialog = false;
      });
    },

    previewInquiry(item) {

      this.previewInquiryItem = item;
      this.previewDialog = true;
    },

    formatDate(item, format) {
      return helpers.format_date(item, format);
    },
  },

  async mounted() {
    await this.getUserType();
    await this.getStatusList();

    let user_roles = this.userRoles.map(a => a.slug);

    if (user_roles.includes("dealer_parts")) {
      this.resources.headers = [
        ...this.resources.headers,
        {
          text: "Actions",
          align: "center",
          value: "editAction",
          class: "muted--text text-no-wrap",
        },
      ];
    }

    if (user_roles.includes("admin_parts")) {
      this.resources.headers = [
        {
          text: "ID",
          value: "id",
          align: "left"
        },
        {
          text: "Dealer Name",
          value: "dealer_name",
          align: "left"
        },
        {
          text: "Name",
          value: "name",
          align: "left"
        },
        {
          text: "Status",
          value: "status",
          align: "left"
        },
        {
          text: "Updated At",
          value: "updated_at",
          align: "left"
        },
        {
          text: "Created At",
          value: "created_at",
          align: "left"
        },
        {
          text: "Actions",
          align: "center",
          value: "editAction",
          class: "muted--text text-no-wrap",
        },
      ];
    }
  },

};
</script>

<style></style>